<template>
    <div class="sim-socials">
        <NuxtLink
            v-for="(social, index) in socials"
            :key="index"
            class="sim-socials__link"
            :to="social.url"
            target="_blank"
            rel="noopener noreferrer nofollow"
            external
        >
            <span class="visually-hidden">
                {{ social.ariaLabel }}
            </span>
            <component
                :is="social.icon"
                class="sim-socials__icon"
                aria-hidden
            />
        </NuxtLink>
    </div>
</template>

<script lang="ts" setup>
import { IconFacebook, IconInstagram, IconXSocial, IconYouTube } from '#components'

const { t } = useI18n()

interface SocialsLink {
    url: string
    ariaLabel: string
    icon: unknown,
}

const appConfig = useAppConfig()

const socials = computed<SocialsLink[]>(() => [
    {
        url: appConfig.links.socials.facebook,
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.facebook')),
        icon: IconFacebook,
    },
    {
        url: appConfig.links.socials.instagram,
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.instagram')),
        icon: IconInstagram,
    },
/*
    {
        url: appConfig.links.socials.x,
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.x')),
        icon: IconXSocial,
    },
    {
        url: appConfig.links.socials.youtube,
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.you_tube')),
        icon: IconYouTube,
    },
*/
])

/**
 * Returns the translated aria label for provided social network.
 * The provided parameter must be a translated string.
 *
 * @example
 * getAriaLabelForSocial(t('accessibility.instagram'))
 * // returns "Go to our Instagram (Opens in new tab)"
 * @param social
 */
function getAriaLabelForSocial(social: string) {
    return `${t('accessibility.socials.go_to_our', [social])} (${t('accessibility.opens_in_new_tab')})`
}

</script>

<style lang="scss" scoped>
$link-padding: 0.5rem;
$icon-size: 1.25rem;

.sim-socials {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin: - $link-padding;
}

.sim-socials__link {
    display: flex;
    align-items: center;
    padding: $link-padding;
    border-radius: $sim-border-radius-xs;

    transition: background-color $sim-trans-time-normal $sim-timing;

    @include mouse-hover {
        background-color: rgba(255 255 255 / 10%);
    }
}

.sim-socials__icon {
    width: $icon-size;
    height: auto;
    max-height: $icon-size;
    color: $sim-c-primary-100;
}

</style>
